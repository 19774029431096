import React from "react";

function SvgColoredCaution({ title = null, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      css={`
        color: ${props.color};
        width: ${props.size};
        height: ${props.size};
        vertical-align: text-top;
      `}
      data-pka-anchor="icon"
      focusable={false}
      {...props}
    >
      {title ? <title>{title}</title> : null}
      <g fill="none" fillRule="evenodd">
        <path
          fill="#E5A823"
          fillRule="nonzero"
          d="M11.995 19.261c-.69 0-1.25-.562-1.25-1.254s.56-1.254 1.25-1.254 1.25.562 1.25 1.254-.56 1.254-1.25 1.254zm-1-9.28h2v6.02h-2V9.98zm10.89 10.587L12.89 2.51c-.34-.68-1.45-.68-1.79 0L2.105 20.568A1.001 1.001 0 003 22.02h17.99a1.001 1.001 0 00.895-1.452z"
        />
        <path
          stroke="#916911"
          strokeWidth={1.14}
          d="M21.965 20.568L12.933 2.51c-.34-.68-1.456-.68-1.796 0L2.105 20.568a1.006 1.006 0 00.898 1.452h18.063a1.006 1.006 0 00.898-1.452z"
        />
      </g>
    </svg>
  );
}

export default SvgColoredCaution;
