import React from "react";

function SvgTimesCircle({ title = null, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      css={`
        color: ${props.color};
        width: ${props.size};
        height: ${props.size};
        vertical-align: text-top;
      `}
      data-pka-anchor="icon"
      focusable={false}
      {...props}
    >
      {title ? <title>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2c-4.405 0-8 3.608-8 8 0 4.392 3.595 8 8 8 4.405 0 8-3.595 8-8 0-4.405-3.608-8-8-8zm-.575 5.739a.8.8 0 001.128.003l2-1.98L14.25 7.46l-1.994 1.974a.8.8 0 00-.002 1.134l1.984 1.984-1.698 1.697-1.984-1.984a.8.8 0 00-1.134.003L7.46 14.249l-1.697-1.697 1.967-1.987a.8.8 0 00-.003-1.129L5.751 7.46l1.697-1.697 1.977 1.976z"
        fill="#8A8A8A"
      />
    </svg>
  );
}

export default SvgTimesCircle;
