import React from "react";

function SvgRefresh({ title = null, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      css={`
        color: ${props.color};
        width: ${props.size};
        height: ${props.size};
        vertical-align: text-top;
      `}
      data-pka-anchor="icon"
      focusable={false}
      {...props}
    >
      {title ? <title>{title}</title> : null}
      <path
        d="M10 3.6c1.738 0 3.34.697 4.52 1.88L12.4 7.6h4.8V2.8l-1.55 1.55A7.943 7.943 0 0010 2c-4.411 0-8 3.588-8 8h1.6c0-3.529 2.871-6.4 6.4-6.4zM10 16.4a6.35 6.35 0 01-4.52-1.88L7.6 12.4H2.8v4.8l1.55-1.55A7.944 7.944 0 0010 18c4.411 0 8-3.588 8-8h-1.6c0 3.529-2.871 6.4-6.4 6.4z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgRefresh;
