import React from "react";

function SvgArrowLeft({ title = null, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      css={`
        color: ${props.color};
        font-size: ${props.size};
        vertical-align: text-top;
      `}
      data-pka-anchor="icon"
      focusable={false}
      {...props}
    >
      {title ? <title>{title}</title> : null}
      <path
        d="M13.5 14.59L8.864 10 13.5 5.41 12.073 4 7.08 8.933a1.5 1.5 0 000 2.134L12.073 16l1.427-1.41z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgArrowLeft;
