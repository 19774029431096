import OriginalOverlay from "@paprika/overlay";

const propTypes = OriginalOverlay.propTypes;

const defaultProps = {};

const Overlay = () => null;

Overlay.propTypes = propTypes;
Overlay.defaultProps = defaultProps;
Overlay.displayName = "Modal.Overlay";

export default Overlay;
